import React, { useState, useEffect } from "react";
import { useWPAjax } from "../../../wp-utils/v1";
import SimpleLoader from "../../../wp-utils/v1/components/Loader/SimpleLoader";

const PasswordForm = ({ passwordProtected, setSrc, nonce, src, source, setQualities }) => {
  const { enabled, heading, button, key, errorMessage } = passwordProtected;
  const { data, refetch: checkPassword, isLoading: passwordChecking } = useWPAjax("h5vp_ajax_handler", { nonce, method: "check_password", model: "Video", key }, true);
  const [passwordError, setPasswordError] = useState(null);
  const [password, setPassword] = useState(null);

  const { text = "Access" } = button || {};

  useEffect(() => {
    setPasswordError(null);
    if (!passwordChecking) {
      if (data) {
        setSrc(data.source);
        setQualities(data.quality);
      } else {
        if (data !== null) {
          setPasswordError(errorMessage || "Password didn't matched");
        }
      }
    }
  }, [passwordChecking]);

  const onSubmit = (e) => {
    e.preventDefault();
    checkPassword({ key, password });
  };

  return (
    <>
      {enabled && !src && (
        <>
          <div className="password_form_overlay"></div>
          <div className="password_form">
            <form onSubmit={onSubmit}>
              {heading && <h3 className="password_form_heading" dangerouslySetInnerHTML={{ __html: heading }}></h3>}
              <input type="password" className="password_form_input" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
              <button className="password_form_button" disabled={passwordChecking}>
                {passwordChecking && <SimpleLoader style={{ borderWidth: "2px" }} />} {text}
              </button>
              {passwordError && <p className="password_form_error" dangerouslySetInnerHTML={{ __html: passwordError }}></p>}
            </form>
          </div>
        </>
      )}
    </>
  );
};

export default PasswordForm;
