import { useEffect, useState, useRef } from "react";

import "./style.scss";
import "./../../public/css/h5vp.css";
import MyPlayer, { extractFileNamesWithoutExtension } from "../public/MyPlayer";
import Overlay from "./Overlay/Overlay";
import PopupOpener from "./PopupOpener/PopupOpener";
import Video from "./Video";
import { isYoutubeURL, useWPAjax } from "../../../wp-utils/v1";
import SimpleLoader from "../../../wp-utils/v1/components/Loader/SimpleLoader";
import Youtube from "./Youtube";
import isVimeoLink from "../utils/isVimeoLink";
import EndScreen from "./EndScreen/EndScreen";
import Style from "./Style";
import Vimeo from "./Vimeo";
import generateUniqueID from "../../../wp-utils/v1/generateUniqueId";
import PasswordForm from "./PasswordForm";

/**
 *
 * @param {attributes} props
 * @param {nonce} props
 * @returns
 */

const VideoPlayer = ({ attributes, nonce = window.h5vpBlock?.nonce }) => {
  const { additionalCSS, additionalID, source, poster, provider, qualities: quality, captions, options, features, uniqueId, styles } = attributes;
  window.attributes = attributes;
  const { popup, overlay, endScreen, passwordProtected = {}, hideYoutubeUI } = features;
  const [src, setSrc] = useState(passwordProtected.enabled ? "" : source);
  const [player, setPlayer] = useState(null);
  const [open, setOpen] = useState(false);
  const [videoSource, setVideoSource] = useState(provider === "library" ? "self-hosted" : provider);
  const { autoplay, muted, loop } = options;
  const { data: isPremium, isLoading } = useWPAjax("pipe_handler", { nonce });
  const { data: videoId, refetch } = useWPAjax("h5vp_ajax_handler", { nonce, method: "create", model: "Video" }, true);
  const [qualities, setQualities] = useState(quality);

  const playerRef = useRef(null);

  useEffect(() => {
    if (open) {
      player?.player.play();
    } else {
      player?.player.pause();
    }
  }, [open]);

  useEffect(() => {
    playerRef.media = playerRef.current?.querySelector("#player");
  }, [playerRef]);

  //initialize player
  useEffect(() => {
    const interval = setInterval(() => {
      const media = playerRef.current?.querySelector("#player");
      if (media) {
        const player = new MyPlayer(media, options, { ...features, qualities, captions });
        setPlayer(player);
        clearInterval(interval);
      }
    }, 100);

    return () => {
      player?.destroy();
    };
  }, [options, features, qualities, captions]); // removed playerRef.current

  useEffect(() => {
    if (player && !player?.nonce) {
      player.setNonce(nonce);
    }
    if (player && !player?.videoId) {
      player.setVideoId(videoId);
    }
  }, [nonce, videoId]);

  useEffect(() => {
    if (player) {
      player.setIsPremium(isPremium);
    }
    if (!isLoading && !isPremium && videoSource !== "self-hosted") {
      player?.destroy();
    }
  }, [isPremium, isLoading, videoSource]);

  useEffect(() => {
    // console.log("source", attributes, videoSource);
    if (!videoSource) {
      if (isYoutubeURL(source)) {
        setVideoSource("youtube");
      } else if (isVimeoLink(source)) {
        setVideoSource("vimeo");
      } else {
        setVideoSource("self-hosted");
      }
    }
  }, [source]);

  useEffect(() => {
    if (source && videoSource) {
      refetch({ src: source, type: videoSource, title: extractFileNamesWithoutExtension(source) });
    }
  }, [source, videoSource]);

  useEffect(() => {
    if (player?.player) {
      player.player.poster = poster;
      // player.player.source = src;
    }
  }, [src, poster]);

  useEffect(() => {
    if (player?.player) {
      player.setSource();
      window.player = player;
      player.dispatchEvent("update-source", { source: src, qualities });
    }
  }, [src]);

  useEffect(() => {
    if (window.sources) {
      window.sources.push(source);
    } else {
      window.sources = [source];
    }
  }, [src, source]);

  useEffect(() => {
    setSrc(passwordProtected.enabled ? "" : source);
  }, [source]);

  if (!source) {
    return <h3>Video source missing</h3>;
  }

  return (
    <div ref={playerRef} id={uniqueId} className={`h5vp_player_temp  ${popup.enabled ? "h5vp_popup_enabled" : ""} ${open ? "h5vp_popup_open" : ""}`}>
      <Style styles={styles} uniqueId={uniqueId} />

      <style>{additionalCSS}</style>

      {isPremium && <PopupOpener setOpen={setOpen} popup={popup} poster={poster} />}

      {/* {password protected form } */}
      <PasswordForm passwordProtected={passwordProtected} nonce={nonce} src={src} setSrc={setSrc} source={source} setQualities={setQualities} />
      <div className={`plyr_wrapper ${hideYoutubeUI ? "hideUI" : ""}`} id={additionalID} data-unique-id={generateUniqueID(5)}>
        <>
          {videoSource === "vimeo" && <Vimeo source={isVimeoLink(src)} />}
          {videoSource === "youtube" && <Youtube source={isYoutubeURL(src)} />}
        </>
        {/* )} */}
        {videoSource === "self-hosted" && (
          <Video
            qualities={src ? qualities : []}
            captions={captions}
            source={passwordProtected.enabled ? src : source}
            poster={poster}
            autoPlay={autoplay}
            muted={muted}
            loop={loop?.active}
            // src={source}
            preload={options.preload}
            isPremium={isPremium}
            playsInline={options.playsinline}
          />
        )}
        {isPremium && (
          <>
            <EndScreen endScreen={endScreen} player={player} />
            <Overlay {...overlay} player={player?.player} />
          </>
        )}
        {/* <Toaster */}
      </div>
    </div>
  );
};

export default VideoPlayer;
